import { render, staticRenderFns } from "./ConfigEdit.vue?vue&type=template&id=6bc3edd6&scoped=true"
import script from "./ConfigEdit.vue?vue&type=script&lang=js"
export * from "./ConfigEdit.vue?vue&type=script&lang=js"
import style0 from "./ConfigEdit.vue?vue&type=style&index=0&id=6bc3edd6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bc3edd6",
  null
  
)

export default component.exports